import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Badge,
  HStack,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Switch,
  FormControl,
  FormLabel,
  Divider,
} from '@chakra-ui/react';
import { collection, query, where, getDocs, updateDoc, doc, writeBatch } from 'firebase/firestore';
import { db } from 'lib/firebase';

const ManagersManagement = () => {
  const [managers, setManagers] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [editedRoles, setEditedRoles] = useState({
    manager: false,
    superManager: false,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchData = async () => {
    try {
      // Fetch all users with manager or superManager role
      const usersRef = collection(db, 'users');
      const [managersSnapshot, superManagersSnapshot] = await Promise.all([
        getDocs(query(usersRef, where('manager', '==', true))),
        getDocs(query(usersRef, where('superManager', '==', true)))
      ]);

      const managersData = managersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const superManagersData = superManagersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Combine and sort by role then name
      const allManagers = [...superManagersData, ...managersData]
        .sort((a, b) => {
          if (a.superManager === b.superManager) {
            return (a.displayName || a.email).localeCompare(b.displayName || b.email);
          }
          return a.superManager ? -1 : 1;
        });

      setManagers(allManagers);

      // Fetch agencies
      const agenciesSnapshot = await getDocs(collection(db, 'agencies'));
      const agenciesData = agenciesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAgencies(agenciesData);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de charger les données',
        status: 'error',
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditManager = (manager) => {
    setSelectedManager(manager);
    setSelectedAgencies(manager.managedAgencies || []);
    setEditedRoles({
      manager: manager.manager || false,
      superManager: manager.superManager || false,
    });
    onOpen();
  };

  const handleUpdateManager = async () => {
    try {
      const batch = writeBatch(db);
      const managerRef = doc(db, 'users', selectedManager.id);

      // Mise à jour des rôles de l'utilisateur
      const updates = {
        manager: editedRoles.manager,
        superManager: editedRoles.superManager,
        managedAgencies: selectedAgencies,
      };

      // Si on retire tous les rôles, nettoyer les données associées
      if (!editedRoles.manager && !editedRoles.superManager) {
        updates.teamSalescode = [];
        updates.managedAgencies = [];
      }

      // Mise à jour des agences
      const agenciesSnapshot = await getDocs(collection(db, 'agencies'));
      
      for (const agencyDoc of agenciesSnapshot.docs) {
        const agencyRef = doc(db, 'agencies', agencyDoc.id);
        const agencyData = agencyDoc.data();
        const isSelected = selectedAgencies.includes(agencyDoc.id);

        // Si l'agence est sélectionnée et l'utilisateur est super manager
        if (isSelected && editedRoles.superManager) {
          batch.update(agencyRef, {
            superManager: selectedManager.salesCode
          });

          // Ajouter tous les managers et vendeurs au teamSalescode du super manager
          const allSalesCodes = [
            ...(agencyData.managers || []),
            ...(agencyData.vendors || [])
          ];
          
          if (!updates.teamSalescode) {
            updates.teamSalescode = [];
          }
          updates.teamSalescode = Array.from(new Set([...updates.teamSalescode, ...allSalesCodes]));
        } 
        // Si l'agence n'est pas sélectionnée ou l'utilisateur n'est plus super manager
        else if (agencyData.superManager === selectedManager.salesCode) {
          batch.update(agencyRef, {
            superManager: null
          });
        }
      }

      // Mise à jour finale de l'utilisateur avec tous les teamSalescode collectés
      batch.update(managerRef, updates);

      await batch.commit();
      await fetchData();

      toast({
        title: 'Succès',
        description: 'Modifications enregistrées',
        status: 'success',
        duration: 3000,
      });
      onClose();
    } catch (error) {
      console.error('Error updating manager:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de mettre à jour le manager',
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <Box>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nom</Th>
            <Th>Rôles</Th>
            <Th>Code vendeur</Th>
            <Th>Agences gérées</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {managers.map((manager) => (
            <Tr key={manager.id}>
              <Td>{manager.displayName || manager.email}</Td>
              <Td>
                <HStack spacing={2}>
                  {manager.superManager && (
                    <Badge colorScheme="purple">Super Manager</Badge>
                  )}
                  {manager.manager && (
                    <Badge colorScheme="blue">Manager</Badge>
                  )}
                </HStack>
              </Td>
              <Td>{manager.salesCode}</Td>
              <Td>
                {manager.managedAgencies?.length || 0} agence(s)
              </Td>
              <Td>
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() => handleEditManager(manager)}
                >
                  Gérer
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Gérer {selectedManager?.displayName || selectedManager?.email}
          </ModalHeader>
          <ModalBody>
            <VStack spacing={6} align="stretch">
              <Box>
                <Text fontWeight="bold" mb={4}>Gestion des rôles</Text>
                <VStack spacing={3} align="stretch">
                  <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">
                      Manager
                    </FormLabel>
                    <Switch
                      isChecked={editedRoles.manager}
                      onChange={(e) => setEditedRoles({
                        ...editedRoles,
                        manager: e.target.checked
                      })}
                    />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">
                      Super Manager
                    </FormLabel>
                    <Switch
                      isChecked={editedRoles.superManager}
                      onChange={(e) => setEditedRoles({
                        ...editedRoles,
                        superManager: e.target.checked
                      })}
                    />
                  </FormControl>
                </VStack>
              </Box>

              <Divider />

              <Box>
                <Text fontWeight="bold" mb={4}>Gestion des agences</Text>
                <VStack spacing={3} align="stretch">
                  {agencies.map((agency) => (
                    <FormControl key={agency.id} display="flex" alignItems="center">
                      <FormLabel mb="0">
                        {agency.name}
                      </FormLabel>
                      <Switch
                        isChecked={selectedAgencies.includes(agency.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedAgencies([...selectedAgencies, agency.id]);
                          } else {
                            setSelectedAgencies(selectedAgencies.filter(id => id !== agency.id));
                          }
                        }}
                      />
                    </FormControl>
                  ))}
                </VStack>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUpdateManager}>
              Enregistrer
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ManagersManagement; 