import React, { useState, useEffect, useContext } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  useToast,
  Select,
  Text,
  VStack,
  useMediaQuery,
  FormErrorMessage,
  Heading,
  SimpleGrid,
  InputGroup,
  InputLeftAddon,
  Divider,
  Spinner,
  Badge,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import axios from 'axios';
import { AuthContext } from 'contexts/AuthContext';
import { db } from 'lib/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useData } from 'contexts/DataProvider';

const AdresseForm = () => {
  const [numeroRue, setNumeroRue] = useState('');
  const [rue, setRue] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [vendeur, setVendeur] = useState('');
  const [adresses, setAdresses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const { user } = useContext(AuthContext);
  const [isMobile] = useMediaQuery("(max-width: 48em)");
  const [teamMembers, setTeamMembers] = useState([]);
  const { getVendorName } = useData();

  useEffect(() => {
    const fetchVendeur = async () => {
      if (user && user.email) {
        try {
          const vendeursCollection = collection(db, 'users');
          const q = query(vendeursCollection, where('email', '==', user.email));
          const vendeursSnapshot = await getDocs(q);
          const vendeurData = vendeursSnapshot.docs.map(doc => doc.data().salesCode);
          if (vendeurData.length > 0) {
            setVendeur(vendeurData[0]);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération du vendeur:", error);
          toast({
            title: 'Erreur',
            description: 'Erreur lors de la récupération du vendeur',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    fetchVendeur();
  }, [user, toast]);

  useEffect(() => {
    if (vendeur) {
      fetchAdresses(vendeur);
      const interval = setInterval(() => {
        fetchAdresses(vendeur);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [vendeur]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (user && (user.profileData?.manager || user.profileData?.leader)) {
        try {
          const teamSalescode = user.profileData.teamSalescode || [];
          const members = teamSalescode.map(code => ({
            salesCode: code,
            name: getVendorName(code)
          }));
          setTeamMembers(members);
          if (members.length > 0) {
            setVendeur(members[0].salesCode);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des membres de l'équipe:", error);
          toast({
            title: 'Erreur',
            description: 'Erreur lors de la récupération des membres de l\'équipe',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    fetchTeamMembers();
  }, [user, getVendorName]);

  const fetchAdresses = async (salesCode) => {
    try {
      const response = await axios.get(`https://app.falconmarketing.fr:3001/api/adresse/${salesCode}`);
      const sortedAdresses = response.data.sort((a, b) => b.id - a.id);
      setAdresses(sortedAdresses.slice(0, 10));
    } catch (error) {
      console.error('Erreur lors de la récupération des adresses:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les adresses',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const validateForm = () => {
    let newErrors = {};
  
    // Validation pour le numéro de rue : ne doit pas se terminer par un espace
    if (numeroRue.endsWith(' ')) {
      newErrors.numeroRue = "Le numéro de rue ne doit pas se terminer par un espace.";
    }
  
    // Nouvelle validation pour le nom de la rue
    if (rue.endsWith(' ')) {
      newErrors.rue = "Le nom de la rue ne doit pas se terminer par un espace.";
    }
  
    // Validation pour le code postal
    if (!/^\d{5}$/.test(codePostal)) {
      newErrors.codePostal = "Le code postal doit être composé de 5 chiffres.";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsSubmitting(true);
    try {
      await axios.post('https://app.falconmarketing.fr:3001/api/adresse', {
        numeroRue,
        rue,
        codePostal,
        salesCode: vendeur,
      });
      
      toast({
        title: 'Adresse soumise',
        description: 'L\'adresse a été soumise avec succès pour affectation.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setNumeroRue('');
      setRue('');
      setCodePostal('');
      
      fetchAdresses(vendeur);
    } catch (error) {
      console.error("Erreur lors de la soumission de l'adresse:", error);
      toast({
        title: 'Erreur',
        description: error.response?.data || 'Une erreur est survenue',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const getBadgeContent = (adresse) => {
    if (adresse.traite) {
      if (adresse.AD) {
        return { text: `AD: ${adresse.AD}`, color: "green", icon: null };
      }
      return { text: "Pas d'adresse", color: "red", icon: null };
    }
    return { text: "En attente", color: "orange", icon: <Spinner size="xs" /> };
  };

  return (
    <Box p={4} mt={isMobile ? '20px' : '20px'}>
      <Heading as="h1" size="xl" mb={6}>Soumission d'adresse</Heading>
      
      <Accordion allowToggle mb={6}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Comment remplir le formulaire ?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text mb={2}>Pour le numéro :</Text>
            <Text mb={2}>- Exemple : "35" est OK</Text>
            <Text mb={2}>- Exemple : pour "35 BIS", mettez "35 B" (avec l'espace entre les deux)</Text>
            <Text mb={2}>Pour la rue :</Text>
            <Text mb={2}>- Exemple : pour "AVENUE DU PEUPLE BELGE", mettez "BELGE" (un seul mot)</Text>
            <Text mb={2}>- Ne pas mettre d'accent ou d'apostrophe</Text>
            <Text mb={4}>Avant de demander l'affectation, vérifiez les adresses éligibles à la fibre ici :</Text>
            <Link href="https://www.sfr.fr/offre-internet/test-eligibilite-adsl-vdsl-fibre" isExternal color="blue.500">
              Test d'éligibilité SFR
            </Link>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={[1, null, 2]} spacing={6}>
          <FormControl isRequired isInvalid={!!errors.numeroRue}>
            <FormLabel htmlFor='numeroRue'>Numéro de rue</FormLabel>
            <Input
              id='numeroRue'
              placeholder='Ex: 123 ou 123 B'
              value={numeroRue}
              onChange={(e) => setNumeroRue(e.target.value)}
            />
            <FormErrorMessage>{errors.numeroRue}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.rue}>
            <FormLabel htmlFor='rue'>Rue</FormLabel>
            <Input
              id='rue'
              placeholder='Ex: Paix'
              value={rue}
              onChange={(e) => setRue(e.target.value)}
            />
            <FormErrorMessage>{errors.rue}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, null, 2]} spacing={6} mt={4}>
          <FormControl isRequired isInvalid={!!errors.codePostal}>
            <FormLabel htmlFor='codePostal'>Code postal</FormLabel>
            <InputGroup>
              <InputLeftAddon children="CP" />
              <Input
                id='codePostal'
                placeholder='Ex: 75001'
                value={codePostal}
                onChange={(e) => setCodePostal(e.target.value)}
              />
            </InputGroup>
            <FormErrorMessage>{errors.codePostal}</FormErrorMessage>
          </FormControl>
        
          <FormControl isRequired>
            <FormLabel htmlFor='vendeur'>Vendeur</FormLabel>
            {user && (user.profileData?.manager || user.profileData?.leader) ? (
              <Select
                id='vendeur'
                value={vendeur}
                onChange={(e) => setVendeur(e.target.value)}
              >
                {teamMembers.map((member) => (
                  <option key={member.salesCode} value={member.salesCode}>
                    {member.name} ({member.salesCode})
                  </option>
                ))}
              </Select>
            ) : (
              <Input
                id='vendeur'
                value={getVendorName(vendeur)}
                isReadOnly
                bg="gray.100"
              />
            )}
          </FormControl>
        </SimpleGrid>

        <Button mt={6} colorScheme='blue' type='submit' isLoading={isSubmitting} width="full">
          Soumettre l'adresse pour affectation
        </Button>
      </form>

      <Divider my={8} />

      <Heading as="h2" size="lg" mb={4}>10 dernières adresses soumises</Heading>
      {adresses.length === 0 ? (
        <Box textAlign="center" py={10}>
          <Spinner size="xl" />
          <Text mt={4}>Chargement des adresses...</Text>
        </Box>
      ) : (
        <SimpleGrid columns={[1, null, 2]} spacing={4}>
          {adresses.map((adresse) => {
            const badgeContent = getBadgeContent(adresse);
            return (
              <Box key={adresse.id} p={3} borderWidth={1} borderRadius="md" boxShadow="sm">
                <VStack align="stretch" spacing={2}>
                  <Text fontWeight="bold" fontSize="sm" noOfLines={2}>
                    {`${adresse.numeroRue} ${adresse.rue}, ${adresse.codePostal}`}
                  </Text>
                  <Badge 
                    colorScheme={badgeContent.color}
                    alignSelf="flex-start"
                    fontSize="xs"
                    px={2}
                    py={1}
                    borderRadius="full"
                  >
                    {badgeContent.icon && <Box as="span" mr={1}>{badgeContent.icon}</Box>}
                    {badgeContent.text}
                  </Badge>
                </VStack>
              </Box>
            );
          })}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default AdresseForm;