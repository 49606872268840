// AgenciesList.js
import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import {
  SimpleGrid,
  Text,
  Box,
  Heading,
  Button,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import AgencyCard from './agencyCard';
import { AuthContext } from 'contexts/AuthContext';
import { FaPlus } from 'react-icons/fa';

const AgenciesList = () => {
  const [agencies, setAgencies] = useState([]);
  const [teams, setTeams] = useState([]);
  const { user } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

// Update the fetchData function (around line 27-67)
const fetchData = async () => {
  if (user && !isDataFetched) {
    setIsDataFetched(true);
    try {
      if (user.profileData?.admin) {
        const agenciesQuery = query(collection(db, 'agencies'));
        const querySnapshot = await getDocs(agenciesQuery);
        const agenciesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAgencies(agenciesData);
      } else if (user.profileData?.superManager) {
        // Update the query to fetch agencies where the user is either a superManager
        // or where their salesCode is listed as a superManager
        const agenciesQuery = query(
          collection(db, 'agencies'),
          where('superManager', '==', user.profileData.salesCode)
        );
        const querySnapshot = await getDocs(agenciesQuery);
        const agenciesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAgencies(agenciesData);
      } else if (user.profileData?.manager) {
          // Récupérer les équipes du manager
          const teamsQuery = query(
            collection(db, 'teams'),
            where('manager', '==', user.profileData.salesCode)
          );
          const teamsSnapshot = await getDocs(teamsQuery);
          const teamsData = teamsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTeams(teamsData);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
      }
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      if (user?.profileData && !isDataFetched) {
        setIsDataFetched(true);
        try {
          await fetchData();
        } catch (error) {
          console.error("Erreur lors du chargement initial:", error);
        }
      }
    };

    initializeData();
  }, [user?.profileData, isDataFetched]);

  const handleDeleteAgency = async (agencyId) => {
    try {
      // Logique de suppression de l'agence
      // ... (code inchangé)
      // Rafraîchir la liste
      setAgencies((prevAgencies) =>
        prevAgencies.filter((agency) => agency.id !== agencyId)
      );
    } catch (error) {
      console.error("Erreur lors de la suppression de l'agence:", error);
    }
  };

  const handleUpdateAgency = async (agencyId, updatedAgency) => {
    try {
      const isTeam = user.profileData?.manager;
      const agencyRef = doc(db, isTeam ? 'teams' : 'agencies', agencyId);
      await updateDoc(agencyRef, updatedAgency);

      // Rafraîchir la liste
      if (user.profileData?.admin) {
        setAgencies((prevAgencies) =>
          prevAgencies.map((agency) =>
            agency.id === agencyId ? { ...agency, ...updatedAgency } : agency
          )
        );
      } else if (user.profileData?.manager) {
        setTeams((prevTeams) =>
          prevTeams.map((team) =>
            team.id === agencyId ? { ...team, ...updatedAgency } : team
          )
        );
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'agence:", error);
    }
  };

  if (!user.profileData?.admin && !user.profileData?.manager) {
    return (
      <Text>
        Vous n'avez pas les autorisations nécessaires pour voir cette page.
      </Text>
    );
  }

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading size="lg">
          {user.profileData?.admin ? 'Liste des agences' : 'Mes agences'}
        </Heading>
        <Button
          colorScheme="blue"
          leftIcon={<FaPlus />}
          onClick={onOpen}
          size="md"
        >
          {user.profileData?.admin ? 'Ajouter une agence' : 'Ajouter une équipe'}
        </Button>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {agencies.map((agency) => (
          <AgencyCard
            key={agency.id}
            agency={agency}
            onDelete={handleDeleteAgency}
            onUpdate={handleUpdateAgency}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default AgenciesList;
