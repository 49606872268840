// AgencyCard.js
import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Text,
  VStack,
  Spinner,
  useColorModeValue,
  Icon,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Divider,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Avatar,
  Badge,
} from '@chakra-ui/react';
import {
  FaUserTie,
  FaUsers,
  FaExternalLinkAlt,
  FaEdit,
  FaTrash,
  FaBuilding,
  FaLayerGroup,
  FaUserAlt,
} from 'react-icons/fa';
import { useData } from 'contexts/DataProvider';
import LeaderTable from './leaderTable';
import { collection, getDocs, doc, updateDoc, deleteDoc, query, where, writeBatch, getDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import { AuthContext } from 'contexts/AuthContext';
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';

// Ajouter ces styles personnalisés au début du fichier
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#E2E8F0',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#CBD5E0',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

// Utiliser le même MenuList que dans ManageAgencies
const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={Math.min(maxHeight, options.length * height)}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const AgencyCard = ({ agency, onDelete, onUpdate, isTeam = false }) => {
  const { user, userAgencies } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendorNames, setVendorNames] = useState({});
  const [teamsCount, setTeamsCount] = useState(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedAgency, setEditedAgency] = useState({ ...agency });
  const [availableVendors, setAvailableVendors] = useState([]);

  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');

  // Fonction pour charger les noms des vendeurs
  const fetchVendorNames = async () => {
    try {
      const vendorsRef = collection(db, 'vendors');
      const vendorsSnapshot = await getDocs(vendorsRef);
      const vendors = {};
      vendorsSnapshot.forEach((doc) => {
        vendors[doc.id] = doc.data().Name;
      });
      setVendorNames(vendors);
    } catch (error) {
      console.error('Erreur lors du chargement des noms des vendeurs:', error);
    }
  };

  useEffect(() => {
    fetchVendorNames();
  }, []);

  // Charger le nombre d'équipes si c'est une agence
  useEffect(() => {
    const fetchTeamsCount = async () => {
      if (!isTeam && agency.id) {
        try {
          const teamsQuery = query(collection(db, 'teams'), where('agencyId', '==', agency.id));
          const teamsSnapshot = await getDocs(teamsQuery);
          setTeamsCount(teamsSnapshot.size);
        } catch (error) {
          console.error('Erreur lors de la récupération des équipes :', error);
        }
      } else {
        setTeamsCount(0);
      }
    };

    fetchTeamsCount();
  }, [agency.id, isTeam]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEdit = () => {
    setEditedAgency({
      ...agency,
      vendors: agency.vendors?.map(id => ({
        value: id,
        label: vendorNames[id] || id
      })) || []
    });
    fetchAvailableVendors();
    setIsEditModalOpen(true);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();

    const confirmMessage = isTeam
      ? "Êtes-vous sûr de vouloir supprimer cette équipe ?"
      : "Êtes-vous sûr de vouloir supprimer cette agence ?";

    if (window.confirm(confirmMessage)) {
      try {
        const batch = writeBatch(db);

        if (isTeam) {
          // Supprimer l'équipe
          const teamRef = doc(db, 'teams', agency.id);
          batch.delete(teamRef);

          // Mettre à jour l'agence parente pour retirer l'ID de l'équipe
          const agencyRef = doc(db, 'agencies', agency.agencyId);
          const agencyDoc = await getDoc(agencyRef);
          if (agencyDoc.exists()) {
            const teams = agencyDoc.data().teams || [];
            batch.update(agencyRef, {
              teams: teams.filter(id => id !== agency.id)
            });
          }

          // Mettre à jour le teamSalescode des managers
          const managersQuery = query(
            collection(db, 'users'),
            where('salesCode', '==', agency.manager)
          );
          const managersSnapshot = await getDocs(managersQuery);
          managersSnapshot.forEach(managerDoc => {
            const teamSalescode = managerDoc.data().teamSalescode || [];
            batch.update(doc(db, 'users', managerDoc.id), {
              teamSalescode: teamSalescode.filter(code => !agency.vendors.includes(code))
            });
          });
        } else {
          // Supprimer l'agence
          const agencyRef = doc(db, 'agencies', agency.id);
          batch.delete(agencyRef);

          // Supprimer toutes les équipes associées
          const teamsQuery = query(collection(db, 'teams'), where('agencyId', '==', agency.id));
          const teamsSnapshot = await getDocs(teamsQuery);
          teamsSnapshot.forEach(teamDoc => {
            batch.delete(teamDoc.ref);
          });

          // Mettre à jour le teamSalescode des managers
          if (agency.managers && agency.managers.length > 0) {
            const managersQuery = query(
              collection(db, 'users'),
              where('salesCode', 'in', agency.managers)
            );
            const managersSnapshot = await getDocs(managersQuery);
            managersSnapshot.forEach(managerDoc => {
              const teamSalescode = managerDoc.data().teamSalescode || [];
              batch.update(doc(db, 'users', managerDoc.id), {
                teamSalescode: teamSalescode.filter(code => !agency.vendors.includes(code))
              });
            });
          }

          // Mettre à jour les vendeurs pour retirer leur agencyId
          agency.vendors?.forEach(vendorId => {
            const vendorRef = doc(db, 'vendors', vendorId);
            batch.update(vendorRef, {
              agencyId: null
            });
          });
        }

        await batch.commit();
        await onDelete(agency.id);

        alert(`${isTeam ? "L'équipe" : "L'agence"} a été supprimée avec succès`);
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
        alert(`Une erreur est survenue lors de la suppression de ${isTeam ? "l'équipe" : "l'agence"}`);
      }
    }
  };

  const handleUpdateAgency = async () => {
    try {
      const batch = writeBatch(db);
      const newVendorIds = editedAgency.vendors.map(v => v.value);
      const oldVendorIds = agency.vendors || [];

      // Mettre à jour les vendeurs retirés (retirer agencyId)
      const removedVendors = oldVendorIds.filter(id => !newVendorIds.includes(id));
      removedVendors.forEach(vendorId => {
        const vendorRef = doc(db, 'vendors', vendorId);
        batch.update(vendorRef, { agencyId: null });
      });

      // Mettre à jour les nouveaux vendeurs (ajouter agencyId)
      const addedVendors = newVendorIds.filter(id => !oldVendorIds.includes(id));
      addedVendors.forEach(vendorId => {
        const vendorRef = doc(db, 'vendors', vendorId);
        batch.update(vendorRef, { agencyId: agency.id });
      });

      // Mettre à jour le teamSalescode des managers
      if (!isTeam) {
        const managersQuery = query(
          collection(db, 'users'),
          where('salesCode', 'in', agency.managers || [])
        );
        const managersSnapshot = await getDocs(managersQuery);
        managersSnapshot.forEach(managerDoc => {
          batch.update(doc(db, 'users', managerDoc.id), {
            teamSalescode: newVendorIds
          });
        });
      } else {
        const managerQuery = query(
          collection(db, 'users'),
          where('salesCode', '==', agency.manager)
        );
        const managerSnapshot = await getDocs(managerQuery);
        managerSnapshot.forEach(managerDoc => {
          batch.update(doc(db, 'users', managerDoc.id), {
            teamSalescode: newVendorIds
          });
        });
      }

      // Mettre à jour l'agence ou l'équipe
      const docRef = doc(db, isTeam ? 'teams' : 'agencies', agency.id);
      batch.update(docRef, {
        name: editedAgency.name,
        vendors: newVendorIds
      });

      await batch.commit();
      await onUpdate(agency.id, {
        ...editedAgency,
        vendors: newVendorIds
      });
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
      alert('Une erreur est survenue lors de la mise à jour');
    }
  };

  // Ajouter une fonction pour charger les vendeurs disponibles
  const fetchAvailableVendors = async () => {
    try {
      if (user.profileData?.admin) {
        // Admin peut voir tous les vendeurs
        const vendorsSnapshot = await getDocs(collection(db, 'vendors'));
        const vendorsData = vendorsSnapshot.docs.map(doc => ({
          value: doc.id,
          label: doc.data().Name
        }));
        vendorsData.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
        setAvailableVendors(vendorsData);
      } else if (user.profileData?.manager) {
        // Manager ne peut voir que les vendeurs de ses agences
        const vendorIds = new Set();
        userAgencies.forEach(userAgency => {
          if (userAgency.vendors && Array.isArray(userAgency.vendors)) {
            userAgency.vendors.forEach(vendorId => vendorIds.add(vendorId));
          }
        });

        if (vendorIds.size > 0) {
          const vendorsQuery = query(
            collection(db, 'vendors'),
            where('__name__', 'in', Array.from(vendorIds))
          );
          const vendorsSnapshot = await getDocs(vendorsQuery);
          const vendorsData = vendorsSnapshot.docs.map(doc => ({
            value: doc.id,
            label: doc.data().Name
          }));
          vendorsData.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
          setAvailableVendors(vendorsData);
        } else {
          setAvailableVendors([]);
        }
      }
    } catch (error) {
      console.error('Erreur lors du chargement des vendeurs:', error);
    }
  };

  const canEdit = user.profileData?.admin || 
                  (user.profileData?.superManager && agency.superManager === user.profileData.salesCode) ||
                  (user.profileData?.manager && isTeam);

  const canDelete = user.profileData?.admin || 
                   (user.profileData?.superManager && agency.superManager === user.profileData.salesCode);

  return (
    <Box
      bg={cardBg}
      color={textColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      p={6}
      width="100%"
      boxShadow="sm"
      transition="all 0.3s"
      _hover={{ bg: hoverBg, boxShadow: 'md' }}
    >
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        <HStack spacing={3}>
          <Avatar
            size="md"
            icon={<FaBuilding fontSize="1.5rem" />}
            bg="blue.500"
            color="white"
          />
          <Box>
            <Text fontSize="xl" fontWeight="bold">
              {agency.name}
            </Text>
            <Badge colorScheme={isTeam ? 'green' : 'blue'}>
              {isTeam ? 'Équipe' : 'Agence'}
            </Badge>
          </Box>
        </HStack>
        <Button size="sm" onClick={handleOpenModal} leftIcon={<FaExternalLinkAlt />}>
          Voir les ventes
        </Button>
      </Flex>

      <Divider mb={4} />

      <VStack spacing={4} align="stretch">
        <Flex justifyContent="space-between" alignItems="center">
          <Stat>
            <StatLabel>
              <Icon as={FaUserTie} mr={2} />
              {isTeam ? "Chef d'quipe" : 'Managers'}
            </StatLabel>
            <StatNumber fontSize="md">
              {isTeam
                ? vendorNames[agency.manager] || agency.manager
                : agency.managers?.map(managerId => vendorNames[managerId] || managerId).join(', ')}
            </StatNumber>
          </Stat>
          <Stat textAlign="right">
            <StatLabel>
              <Icon as={isTeam ? FaUserAlt : FaLayerGroup} mr={2} />
              {isTeam ? 'Vendeurs' : 'Équipes'}
            </StatLabel>
            <StatNumber>{isTeam ? agency.vendors?.length || 0 : teamsCount}</StatNumber>
          </Stat>
        </Flex>
      </VStack>

      {canEdit && (
        <Flex justifyContent="flex-end" mt={4}>
          <Button leftIcon={<FaEdit />} onClick={handleEdit} size="sm" mr={2}>
            Modifier
          </Button>
          {canDelete && (
            <Button
              leftIcon={<FaTrash />}
              onClick={handleDelete}
              size="sm"
              colorScheme="red"
            >
              Supprimer
            </Button>
          )}
        </Flex>
      )}

      {/* Modal pour LeaderTable */}
      <LeaderTable
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        salesCodes={agency.vendors}
        agencyId={agency.id}
        isTeam={isTeam}
      />


<Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
  <ModalOverlay />
  <ModalContent maxW="600px">
    <ModalHeader>Modifier {isTeam ? "l'équipe" : "l'agence"}</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <VStack spacing={4}>
        <FormControl>
          <FormLabel>Nom {isTeam ? "de l'équipe" : "de l'agence"}</FormLabel>
          <Input
            value={editedAgency.name}
            onChange={(e) =>
              setEditedAgency({ ...editedAgency, name: e.target.value })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Gestion des vendeurs</FormLabel>
          <Select
            isMulti
            options={availableVendors}
            value={editedAgency.vendors}
            onChange={(selectedOptions) =>
              setEditedAgency({ ...editedAgency, vendors: selectedOptions || [] })
            }
            placeholder="Sélectionnez les vendeurs..."
            closeMenuOnSelect={false}
            components={{ MenuList }}
            styles={customStyles}
          />
          <Text fontSize="sm" color="gray.500" mt={1}>
            {editedAgency.vendors?.length || 0} vendeur(s) sélectionné(s)
          </Text>
        </FormControl>
      </VStack>
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={handleUpdateAgency}>
        Mettre à jour
      </Button>
      <Button variant="ghost" onClick={() => setIsEditModalOpen(false)}>
        Annuler
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>
    </Box>
  );
};

export default AgencyCard;
