import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
  writeBatch,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { db } from "lib/firebase";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Box,
  Tooltip,
  Text,
  Select,
  useToast,
  Tag,
  TagLabel,
  TagCloseButton,
  Checkbox,
  Heading,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  InputGroup,
  InputLeftElement,
  Spinner,
  Flex,
  SimpleGrid,
} from "@chakra-ui/react";
import { MdLocationOn } from "react-icons/md";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { SearchIcon } from "@chakra-ui/icons";

const ListDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [docIdToDelete, setDocIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allVendors, setAllVendors] = useState([]);
  const [allAgencies, setAllAgencies] = useState([]);
  const [allTeams, setAllTeams] = useState([]);

  const MAPBOX_TOKEN = "YOUR_MAPBOX_TOKEN";
  const toast = useToast();
  const cancelRef = useRef();

  // Fonction pour charger tous les vendeurs
  const fetchAllVendors = useCallback(async () => {
    try {
      const vendorsRef = collection(db, "vendors");
      const vendorsSnapshot = await getDocs(vendorsRef);

      const vendorsData = vendorsSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name || doc.data().Name || "Sans nom",
      }));

      vendorsData.sort((a, b) => a.name.localeCompare(b.name));
      setAllVendors(vendorsData);
    } catch (error) {
      console.error("Erreur lors du chargement des vendeurs:", error);
    }
  }, []);

  // Fonction pour charger toutes les agences
  const fetchAllAgencies = useCallback(async () => {
    try {
      const agenciesRef = collection(db, "agencies");
      const agenciesSnapshot = await getDocs(agenciesRef);

      const agenciesData = agenciesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAllAgencies(agenciesData);
    } catch (error) {
      console.error("Erreur lors du chargement des agences:", error);
    }
  }, []);

  // Fonction pour charger toutes les équipes
  const fetchAllTeams = useCallback(async () => {
    try {
      const teamsRef = collection(db, "teams");
      const teamsSnapshot = await getDocs(teamsRef);

      const teamsData = teamsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAllTeams(teamsData);
    } catch (error) {
      console.error("Erreur lors du chargement des équipes:", error);
    }
  }, []);

  useEffect(() => {
    fetchAllVendors();
    fetchAllAgencies();
    fetchAllTeams();
  }, [fetchAllVendors, fetchAllAgencies, fetchAllTeams]);

  const findVendorNameById = useCallback(
    (vendorId) => {
      if (!vendorId) return "N/A";

      const vendor = allVendors.find((v) => v.id === vendorId.toString());

      return vendor ? vendor.name : "ID inconnu";
    },
    [allVendors]
  );

  const filteredDocuments = useMemo(() => {
    return documents.filter((doc) => {
      const emailMatches =
        doc.email && doc.email.toLowerCase().includes(searchTerm.toLowerCase());
      const vendorNameMatches = findVendorNameById(doc.salesCode)
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      return emailMatches || vendorNameMatches;
    });
  }, [documents, searchTerm, findVendorNameById]);

  const fetchDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const docsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      docsArray.sort((a, b) => {
        const aLastLogin = a.lastLogin ? a.lastLogin.seconds : 0;
        const bLastLogin = b.lastLogin ? b.lastLogin.seconds : 0;
        return bLastLogin - aLastLogin;
      });

      setDocuments(docsArray);
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des documents:", error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredDocuments.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, itemsPerPage, filteredDocuments]);

  const paginate = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const addVendorToDocument = (vendorId) => {
    if (!selectedDoc.teamSalescode.includes(vendorId)) {
      const updatedTeamSalescode = [...selectedDoc.teamSalescode, vendorId];
      setSelectedDoc({ ...selectedDoc, teamSalescode: updatedTeamSalescode });
    } else {
      toast({
        title: "Vendeur existant",
        description: "Ce vendeur est déjà ajouté aux teamSalescode.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const removeVendor = (vendorId) => {
    const updatedTeamSalescode = selectedDoc.teamSalescode.filter(
      (code) => code !== vendorId
    );
    setSelectedDoc({ ...selectedDoc, teamSalescode: updatedTeamSalescode });
  };

  const editDocument = (doc) => {
    doc.teamSalescode = doc.teamSalescode || [];
    setSelectedDoc(doc);
    onOpen();
  };

  const updateLocalDocument = (field, value) => {
    const updatedDocument = { ...selectedDoc, [field]: value };
    setSelectedDoc(updatedDocument);
    const updatedDocuments = documents.map((doc) =>
      doc.id === updatedDocument.id ? updatedDocument : doc
    );
    setDocuments(updatedDocuments);
  };

  const handleSave = async () => {
    try {
      const batch = writeBatch(db);

      // Mise à jour du document utilisateur
      const userRef = doc(db, "users", selectedDoc.id);
      batch.update(userRef, selectedDoc);

      // Si le salesCode a changé, mettre à jour les agences/équipes
      if (selectedDoc.salesCode) {
        // Mettre à jour l'agence si l'utilisateur est manager
        const agenciesManaged = allAgencies.filter(
          (agency) => agency.manager === selectedDoc.salesCode
        );
        agenciesManaged.forEach((agency) => {
          const agencyRef = doc(db, "agencies", agency.id);
          batch.update(agencyRef, { manager: selectedDoc.salesCode });
        });

        // Mettre à jour les équipes si l'utilisateur est manager
        const teamsManaged = allTeams.filter(
          (team) => team.manager === selectedDoc.salesCode
        );
        teamsManaged.forEach((team) => {
          const teamRef = doc(db, "teams", team.id);
          batch.update(teamRef, { manager: selectedDoc.salesCode });
        });
      }

      // Mettre à jour les agences/équipes avec les modifications de teamSalescode
      if (selectedDoc.teamSalescode) {
        // Supposons que vous avez une logique pour déterminer quelles agences/équipes sont concernées
        // Vous pouvez mettre à jour les agences/équipes correspondantes ici
      }

      await batch.commit();

      toast({
        title: "Document mis à jour",
        description: "Les modifications ont été enregistrées avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      console.error("Erreur lors de la sauvegarde :", error);
      toast({
        title: "Échec de la mise à jour",
        description:
          "Une erreur est survenue lors de la sauvegarde des modifications.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (docId) => {
    try {
      const batch = writeBatch(db);

      // Supprimer les références à l'utilisateur dans les agences
      allAgencies.forEach((agency) => {
        if (agency.manager === selectedDoc.salesCode) {
          const agencyRef = doc(db, "agencies", agency.id);
          batch.update(agencyRef, { manager: null });
        }
        if (agency.vendors.includes(selectedDoc.salesCode)) {
          const agencyRef = doc(db, "agencies", agency.id);
          batch.update(agencyRef, {
            vendors: arrayRemove(selectedDoc.salesCode),
          });
        }
      });

      // Supprimer les références à l'utilisateur dans les équipes
      allTeams.forEach((team) => {
        if (team.manager === selectedDoc.salesCode) {
          const teamRef = doc(db, "teams", team.id);
          batch.update(teamRef, { manager: null });
        }
        if (team.vendors.includes(selectedDoc.salesCode)) {
          const teamRef = doc(db, "teams", team.id);
          batch.update(teamRef, {
            vendors: arrayRemove(selectedDoc.salesCode),
          });
        }
      });

      // Supprimer le document utilisateur
      const userRef = doc(db, "users", docId);
      batch.delete(userRef);

      await batch.commit();

      toast({
        title: "Document supprimé",
        description: "Le document a été supprimé avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
      fetchDocuments();
    } catch (error) {
      console.error("Erreur lors de la suppression du document :", error);
      toast({
        title: "Échec de la suppression",
        description:
          "Une erreur est survenue lors de la suppression du document.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const requestDelete = (docId) => {
    setIsDeleteConfirmationOpen(true);
    setDocIdToDelete(docId);
  };

  const renderedDocuments = currentItems.map((doc) => {
    return (
      <Tr key={doc.id}>
        <Td>{doc.email}</Td>
        <Td>{doc.salesCode ? findVendorNameById(doc.salesCode) : "N/A"}</Td>
        <Td>
          {doc.teamSalescode &&
          Array.isArray(doc.teamSalescode) &&
          doc.teamSalescode.length > 0 ? (
            <Tooltip
              label={doc.teamSalescode
                .map((id) => findVendorNameById(id))
                .join(", ")}
              placement="bottom"
              hasArrow
            >
              <Tag
                colorScheme="blue"
                cursor="pointer"
                _hover={{ bg: "blue.600", color: "white" }}
              >
                {doc.teamSalescode.length} membres
              </Tag>
            </Tooltip>
          ) : (
            "N/A"
          )}
        </Td>
        <Td>
          {doc.lastLogin
            ? new Date(doc.lastLogin.seconds * 1000).toLocaleString()
            : "Inconnue"}
        </Td>
        <Td>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => editDocument(doc)}
            _hover={{ bg: "blue.600", transform: "translateY(-2px)" }}
            transition="all 0.3s"
          >
            Modifier
          </Button>
        </Td>
      </Tr>
    );
  });

  return (
    <Box p={5} mt={24}>
      <Heading as="h1" size="xl" mb={2}>
        Liste des Utilisateurs
      </Heading>
      <Text mb={5}>
        Gérez les profils des utilisateurs et leurs permissions.
      </Text>

      <Box my={5}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Rechercher par email ou nom de vendeur..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </Box>

      {isLoading ? (
        <Flex justify="center" align="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box overflowX="auto" shadow="md" borderWidth="1px" borderRadius="lg">
          <Table variant="simple" colorScheme="blue">
            <Thead bg="blue.500">
              <Tr>
                <Th color="white">Email</Th>
                <Th color="white">Vendeur</Th>
                <Th color="white">Équipe</Th>
                <Th color="white">Dernière connexion</Th>
                <Th color="white">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>{renderedDocuments}</Tbody>
          </Table>
        </Box>
      )}

      {/* Pagination */}
      <Flex justify="center" mt={4}>
        <Button
          onClick={() => paginate(currentPage - 1)}
          isDisabled={currentPage === 1}
          mr={2}
        >
          Précédent
        </Button>
        {Array.from(
          {
            length: Math.min(
              5,
              Math.ceil(filteredDocuments.length / itemsPerPage)
            ),
          },
          (_, i) => {
            const pageNumber = currentPage - 2 + i;
            if (
              pageNumber > 0 &&
              pageNumber <= Math.ceil(filteredDocuments.length / itemsPerPage)
            ) {
              return (
                <Button
                  key={pageNumber}
                  onClick={() => paginate(pageNumber)}
                  colorScheme={currentPage === pageNumber ? "blue" : "gray"}
                  mx={1}
                >
                  {pageNumber}
                </Button>
              );
            }
            return null;
          }
        )}
        <Button
          onClick={() => paginate(currentPage + 1)}
          isDisabled={
            currentPage ===
            Math.ceil(filteredDocuments.length / itemsPerPage)
          }
          ml={2}
        >
          Suivant
        </Button>
      </Flex>

      {/* Modal d'édition */}
      {selectedDoc && (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Modifier le profil de {selectedDoc.email}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SimpleGrid columns={2} spacing={5}>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    value={selectedDoc.email}
                    onChange={(e) =>
                      updateLocalDocument("email", e.target.value)
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Vendeur</FormLabel>
                  <Select
                    placeholder="Sélectionnez un vendeur..."
                    onChange={(e) => updateLocalDocument("salesCode", e.target.value)}
                    value={selectedDoc.salesCode || ""}
                  >
                    {allVendors.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </SimpleGrid>

              {/* Membres de l'équipe */}
              <FormControl mt={4}>
                <FormLabel>Membres de l'équipe</FormLabel>
                <Box>
                  {selectedDoc.teamSalescode &&
                    selectedDoc.teamSalescode.map((code) => (
                      <Tag
                        size="lg"
                        key={code}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="blue"
                        m={1}
                      >
                        <TagLabel>{findVendorNameById(code)}</TagLabel>
                        <TagCloseButton onClick={() => removeVendor(code)} />
                      </Tag>
                    ))}
                </Box>
                <Select
                  mt={4}
                  placeholder="Ajoutez un membre à l'équipe..."
                  onChange={(e) => addVendorToDocument(e.target.value)}
                >
                  {allVendors
                    .filter(
                      (vendor) =>
                        !selectedDoc.teamSalescode?.includes(vendor.id)
                    )
                    .map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.name}
                      </option>
                    ))}
                </Select>
              </FormControl>

              <Box mt={4}>
                <Heading size="sm" mb={2}>
                  Permissions
                </Heading>
                <Stack direction="row">
                  <Tooltip
                    label="Accès complet à toutes les fonctionnalités"
                    hasArrow
                  >
                    <Checkbox
                      isChecked={selectedDoc.admin}
                      onChange={(e) =>
                        updateLocalDocument("admin", e.target.checked)
                      }
                    >
                      Admin
                    </Checkbox>
                  </Tooltip>
                  <Tooltip
                    label="Gestion des équipes et des rapports"
                    hasArrow
                  >
                    <Checkbox
                      isChecked={selectedDoc.manager}
                      onChange={(e) =>
                        updateLocalDocument("manager", e.target.checked)
                      }
                    >
                      Manager
                    </Checkbox>
                  </Tooltip>
                  <Tooltip
                    label="Supervision d'une équipe spécifique"
                    hasArrow
                  >
                    <Checkbox
                      isChecked={selectedDoc.leader}
                      onChange={(e) =>
                        updateLocalDocument("leader", e.target.checked)
                      }
                    >
                      Chef d'équipe
                    </Checkbox>
                  </Tooltip>
                </Stack>
              </Box>

              <FormControl mt={4}>
                <FormLabel>Dernière connexion</FormLabel>
                <Text>
                  {selectedDoc.lastLogin
                    ? new Date(
                        selectedDoc.lastLogin.seconds * 1000
                      ).toLocaleString()
                    : "Date de dernière connexion inconnue"}
                </Text>
              </FormControl>

              {/* Intégration de la carte */}
              <Box height="300px" width="100%" mt={2}>
                <Map
                  initialViewState={{
                    latitude: selectedDoc.geopoint?.latitude || 37.7577,
                    longitude: selectedDoc.geopoint?.longitude || -122.4376,
                    zoom: 14,
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                  }}
                  mapStyle="mapbox://styles/mapbox/standard"
                  mapboxAccessToken={MAPBOX_TOKEN}
                >
                  {selectedDoc.geopoint && (
                    <Marker
                      latitude={selectedDoc.geopoint.latitude}
                      longitude={selectedDoc.geopoint.longitude}
                      offsetLeft={-20}
                      offsetTop={-10}
                    >
                      <MdLocationOn size={30} color="red" />
                    </Marker>
                  )}
                </Map>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="red"
                mr={3}
                onClick={() => requestDelete(selectedDoc.id)}
                _hover={{ bg: "red.600", transform: "translateY(-2px)" }}
                transition="all 0.3s"
              >
                Supprimer
              </Button>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleSave}
                _hover={{ bg: "blue.600", transform: "translateY(-2px)" }}
                transition="all 0.3s"
              >
                Sauvegarder
              </Button>
              <Button
                onClick={onClose}
                _hover={{ bg: "gray.300", transform: "translateY(-2px)" }}
                transition="all 0.3s"
              >
                Annuler
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {/* AlertDialog de suppression */}
      <AlertDialog
        isOpen={isDeleteConfirmationOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le Document
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr? Vous ne pouvez pas annuler cette action par la
              suite.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setIsDeleteConfirmationOpen(false)}>
                Annuler
              </Button>
              <Button
                colorScheme="red"
                onClick={() => handleDelete(docIdToDelete)}
                ml={3}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ListDocuments;
