import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  VStack,
  Stack,
  useBreakpointValue,
  Flex,
  IconButton,
  Badge,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogContent,
  useDisclosure,

} from '@chakra-ui/react';
import { collection, query, orderBy, getDocs, addDoc, where, deleteDoc, doc, writeBatch, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, deleteUser } from 'firebase/auth';
import Card from 'components/card/Card';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'lib/firebase';

const VendorHistory = () => {
  const [vendors, setVendors] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [email, setEmail] = useState('');
  const [selectedAgency, setSelectedAgency] = useState('');
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [vendorAccounts, setVendorAccounts] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Ajustez selon vos besoins
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const cancelRef = useRef();

  const toast = useToast();
  const auth = getAuth();

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  // Charger les agences
  useEffect(() => {
    const fetchAgencies = async () => {
      try {
        const agenciesRef = collection(db, 'agencies');
        const agenciesSnapshot = await getDocs(agenciesRef);
        const agenciesData = agenciesSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name
        }));
        setAgencies(agenciesData);
      } catch (error) {
        console.error('Erreur lors de la récupération des agences:', error);
        toast({
          title: 'Erreur',
          description: 'Erreur lors du chargement des agences.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchAgencies();
  }, [toast]);

  // Charger les vendeurs et les comptes correspondants
  useEffect(() => {
    const fetchVendorsAndAccounts = async () => {
      try {
        // Récupérer les vendeurs
        const vendorsRef = collection(db, 'vendors');
        const q = query(vendorsRef, orderBy('lastUpdated', 'desc'));
        const snapshot = await getDocs(q);
        const vendorsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setVendors(vendorsData);

        // Extraire les salesCodes
        const vendorIds = vendorsData.map(vendor => vendor.id);

        // Diviser en lots de 10
        const batchSize = 10;
        const batches = [];
        for (let i = 0; i < vendorIds.length; i += batchSize) {
          batches.push(vendorIds.slice(i, i + batchSize));
        }

        const accounts = {};

        // Récupérer les comptes utilisateurs correspondants
        for (const batch of batches) {
          const usersRef = collection(db, 'users');
          const usersQuery = query(usersRef, where('salesCode', 'in', batch));
          const usersSnapshot = await getDocs(usersQuery);
          usersSnapshot.docs.forEach(doc => {
            const data = doc.data();
            if (data.salesCode) {
              accounts[data.salesCode] = {
                uid: data.uid,
                docId: doc.id
              };
            }
          });
        }

        setVendorAccounts(accounts);
      } catch (error) {
        console.error('Erreur lors de la récupération des vendeurs et des comptes:', error);
        toast({
          title: 'Erreur',
          description: 'Erreur lors du chargement des données.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchVendorsAndAccounts();
  }, [toast]);

  const handleInvite = async () => {
    if (!email) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir l\'email',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Vérifier si l'utilisateur existe déjà
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Si l'utilisateur existe, proposer de réinitialiser le mot de passe
        try {
          await sendPasswordResetEmail(auth, email);
          toast({
            title: 'Email envoyé',
            description: 'Un email de réinitialisation a été envoyé',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: 'Erreur',
            description: 'Erreur lors de l\'envoi de l\'email de réinitialisation',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
        setIsInviteModalOpen(false);
        return;
      }

      const secondaryAuth = getAuth();
      const originalUser = secondaryAuth.currentUser;

      try {
        // Créer l'utilisateur dans Firebase Auth
        const tempPassword = Math.random().toString(36).slice(-8);
        const userCredential = await createUserWithEmailAndPassword(secondaryAuth, email, tempPassword);

        // Utiliser une batch pour les opérations Firestore
        const batch = writeBatch(db);

        // 1. Créer le document utilisateur
        const userDocRef = doc(collection(db, 'users'));
        const userData = {
          email: email,
          salesCode: selectedVendor.id,
          lastLogin: null,
          admin: false,
          manager: false,
          leader: false,
          uid: userCredential.user.uid,
          createdAt: new Date()
        };

        // Ajouter l'agencyId seulement si une agence est sélectionnée
        if (selectedAgency) {
          userData.agencyId = selectedAgency;
        }

        batch.set(userDocRef, userData);

        // 2. Mettre à jour le document du vendeur et l'agence seulement si une agence est sélectionnée
        if (selectedAgency) {
          const vendorRef = doc(db, 'vendors', selectedVendor.id);
          batch.update(vendorRef, {
            agencyId: selectedAgency
          });

          const agencyRef = doc(db, 'agencies', selectedAgency);
          batch.update(agencyRef, {
            vendors: arrayUnion(selectedVendor.id)
          });
        }

        await batch.commit();
        await sendPasswordResetEmail(secondaryAuth, email);

        if (originalUser) {
          await secondaryAuth.updateCurrentUser(originalUser);
        }

        toast({
          title: 'Succès',
          description: 'Utilisateur créé et email de configuration envoyé',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        setEmail('');
        setSelectedAgency('');
        setIsInviteModalOpen(false);

        setVendorAccounts(prevAccounts => ({
          ...prevAccounts,
          [selectedVendor.id]: {
            uid: userCredential.user.uid,
            docId: userDocRef.id
          }
        }));

      } catch (error) {
        if (originalUser) {
          await secondaryAuth.updateCurrentUser(originalUser);
        }
        throw error;
      }

    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: 'Erreur',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const requestDelete = (vendorId) => {
    setVendorToDelete(vendorId);
    setIsDeleteConfirmationOpen(true);
  };

  const handleDeleteAccount = async () => {
    if (!vendorToDelete || !vendorAccounts[vendorToDelete]) return;

    try {
      const userDocId = vendorAccounts[vendorToDelete].docId;
      const uid = vendorAccounts[vendorToDelete].uid;

      // 1. Récupérer les informations de l'agence actuelle
      const vendorDoc = await getDoc(doc(db, 'vendors', vendorToDelete));
      const currentAgencyId = vendorDoc.data()?.agencyId;

      // 2. Supprimer le compte Auth avec gestion d'erreur spécifique
      try {
        const deleteUserAuth = httpsCallable(functions, 'deleteUserAuth');
        await deleteUserAuth({ uid });
      } catch (authError) {
        console.error('Erreur lors de la suppression Auth:', authError);
        // Continuer même si la suppression Auth échoue
      }

      // 3. Supprimer les données Firestore
      try {
        const batch = writeBatch(db);

        // Supprimer le document utilisateur
        if (userDocId) {
          const userDocRef = doc(db, 'users', userDocId);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            batch.delete(userDocRef);
          }
        }

        // Mettre à jour le vendeur
        const vendorRef = doc(db, 'vendors', vendorToDelete);
        batch.update(vendorRef, { 
          agencyId: null,
          lastUpdated: new Date()
        });

        // Si le vendeur était associé à une agence, le retirer de la liste des vendeurs
        if (currentAgencyId) {
          const agencyRef = doc(db, 'agencies', currentAgencyId);
          const agencyDoc = await getDoc(agencyRef);
          if (agencyDoc.exists()) {
            batch.update(agencyRef, {
              vendors: arrayRemove(vendorToDelete)
            });
          }
        }

        // Exécuter les opérations Firestore
        await batch.commit();

        // Mettre à jour l'état local
        setVendorAccounts(prevAccounts => {
          const newAccounts = { ...prevAccounts };
          delete newAccounts[vendorToDelete];
          return newAccounts;
        });

        toast({
          title: 'Succès',
          description: 'Compte supprimé avec succès',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

      } catch (firestoreError) {
        console.error('Erreur Firestore:', firestoreError);
        throw new Error('Erreur lors de la mise à jour des données');
      }

    } catch (error) {
      console.error('Erreur complète:', error);
      
      let errorMessage = 'Erreur lors de la suppression du compte';
      if (error.code === 'functions/not-found') {
        errorMessage = 'Utilisateur non trouvé';
      } else if (error.code === 'functions/unauthenticated') {
        errorMessage = 'Vous devez être connecté';
      } else if (error.code === 'functions/permission-denied') {
        errorMessage = 'Vous n\'avez pas les permissions nécessaires';
      } else if (error.code === 'functions/internal') {
        errorMessage = 'Erreur interne du serveur. Les données ont peut-être été partiellement mises à jour.';
      }

      toast({
        title: 'Erreur',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteConfirmationOpen(false);
      setVendorToDelete(null);
    }
  };

  const isMobile = useBreakpointValue({ base: true, md: false });
  const tableSize = useBreakpointValue({ sm: 'sm', lg: 'md' });

  // Ajouter searchQuery dans les états
  const [searchQuery, setSearchQuery] = useState('');

  // Ajouter cette fonction de filtrage avant le return
  const filteredVendors = useMemo(() => {
    return vendors.filter(vendor => 
      vendor.Name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [vendors, searchQuery]);

  // Pagination
  const totalPages = Math.ceil(filteredVendors.length / itemsPerPage);

  const currentVendors = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredVendors.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredVendors, currentPage]);

  // Composant pour la vue mobile d'un vendeur
  const VendorMobileCard = React.memo(({ vendor }) => (
    <Box
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      mb={2}
      bg="white"
      shadow="sm"
    >
      <VStack align="stretch" spacing={2}>
        <Flex justify="space-between" align="center">
          <Text fontWeight="bold">{vendor.Name}</Text>
          {vendorAccounts[vendor.id] && (
            <Badge colorScheme="green" display="flex" alignItems="center">
              <FaCheck size="12px" style={{ marginRight: '4px' }} /> Compte actif
            </Badge>
          )}
        </Flex>

        <Text fontSize="sm" color="gray.600">
          Mise à jour: {formatDate(vendor.lastUpdated)}
        </Text>

        <Flex gap={2}>
          <Button
            colorScheme="blue"
            size="sm"
            flex="1"
            isDisabled={vendorAccounts[vendor.id]}
            onClick={() => {
              setSelectedVendor(vendor);
              setIsInviteModalOpen(true);
            }}
            leftIcon={<FaCheck />}
          >
            Inviter
          </Button>
          {vendorAccounts[vendor.id] && (
            <>
              <Button
                colorScheme="green"
                size="sm"
                onClick={async () => {
                  try {
                    const usersRef = collection(db, 'users');
                    const q = query(usersRef, where('salesCode', '==', vendor.id));
                    const querySnapshot = await getDocs(q);
                    const userDoc = querySnapshot.docs[0];
                    if (userDoc) {
                      await sendPasswordResetEmail(auth, userDoc.data().email);
                      toast({
                        title: 'Email envoyé',
                        description: 'Un email de réinitialisation a été envoyé',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                  } catch (error) {
                    toast({
                      title: 'Erreur',
                      description: 'Erreur lors de l\'envoi de l\'email de réinitialisation',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                }}
              >
                Réinitialiser
              </Button>
              <IconButton
                colorScheme="red"
                size="sm"
                icon={<FaTimes />}
                onClick={() => requestDelete(vendor.id)}
                aria-label="Supprimer le compte"
              />
            </>
          )}
        </Flex>
      </VStack>
    </Box>
  ));

  return (
    <Card>
      {/* Modal d'invitation */}
      <Modal isOpen={isInviteModalOpen} onClose={() => setIsInviteModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inviter {selectedVendor?.Name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Entrez l'email du vendeur"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Agence</FormLabel>
                <Select
                  value={selectedAgency}
                  onChange={(e) => setSelectedAgency(e.target.value)}
                  placeholder="Sélectionnez une agence"
                >
                  {agencies.map((agency) => (
                    <option key={agency.id} value={agency.id}>
                      {agency.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Button colorScheme="blue" onClick={handleInvite} width="100%">
                Créer le compte
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box p={4}>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Historique des vendeurs
        </Text>
        
        <Box mb={4}>
          <Input
            placeholder="Rechercher un vendeur..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1); // Réinitialiser la pagination lors d'une recherche
            }}
            maxW="300px"
          />
        </Box>

        {isMobile ? (
          // Vue Mobile
          <Stack spacing={4}>
            {currentVendors.map((vendor) => (
              <VendorMobileCard key={vendor.id} vendor={vendor} />
            ))}
          </Stack>
        ) : (
          // Vue Desktop
          <Box overflowX="auto">
            <Table variant="simple" size={tableSize}>
              <Thead>
                <Tr>
                  <Th>Nom</Th>
                  <Th>Dernière mise à jour</Th>
                  <Th>Compte</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentVendors.map((vendor) => (
                  <Tr key={vendor.id}>
                    <Td maxW="200px" isTruncated>{vendor.Name}</Td>
                    <Td>{formatDate(vendor.lastUpdated)}</Td>
                    <Td>
                      {vendorAccounts[vendor.id] && (
                        <Badge colorScheme="green" display="flex" alignItems="center" width="fit-content">
                          <FaCheck size="12px" style={{ marginRight: '4px' }} /> Actif
                        </Badge>
                      )}
                    </Td>
                    <Td>
                      <Flex gap={2}>
                        <Button
                          colorScheme="blue"
                          size="sm"
                          isDisabled={vendorAccounts[vendor.id]}
                          onClick={() => {
                            setSelectedVendor(vendor);
                            setIsInviteModalOpen(true);
                          }}
                          leftIcon={<FaCheck />}
                        >
                          Inviter
                        </Button>
                        {vendorAccounts[vendor.id] && (
                          <>
                            <Button
                              colorScheme="green"
                              size="sm"
                              onClick={async () => {
                                try {
                                  const usersRef = collection(db, 'users');
                                  const q = query(usersRef, where('salesCode', '==', vendor.id));
                                  const querySnapshot = await getDocs(q);
                                  const userDoc = querySnapshot.docs[0];
                                  if (userDoc) {
                                    await sendPasswordResetEmail(auth, userDoc.data().email);
                                    toast({
                                      title: 'Email envoyé',
                                      description: 'Un email de réinitialisation a été envoyé',
                                      status: 'success',
                                      duration: 5000,
                                      isClosable: true,
                                    });
                                  }
                                } catch (error) {
                                  toast({
                                    title: 'Erreur',
                                    description: 'Erreur lors de l\'envoi de l\'email de réinitialisation',
                                    status: 'error',
                                    duration: 5000,
                                    isClosable: true,
                                  });
                                }
                              }}
                            >
                              Réinitialiser
                            </Button>
                            <IconButton
                              colorScheme="red"
                              size="sm"
                              icon={<FaTimes />}
                              onClick={() => requestDelete(vendor.id)}
                              aria-label="Supprimer le compte"
                            />
                          </>
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}

        {/* Contrôles de pagination */}
        {totalPages > 1 && (
          <Flex justify="center" align="center" mt={4}>
            <Button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              mr={2}
            >
              Précédent
            </Button>
            <Text>
              Page {currentPage} sur {totalPages}
            </Text>
            <Button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              ml={2}
            >
              Suivant
            </Button>
          </Flex>
        )}
      </Box>

      {/* Ajouter la boîte de dialogue de confirmation */}
      <AlertDialog
        isOpen={isDeleteConfirmationOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le compte
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr ? Cette action ne peut pas être annulée.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteConfirmationOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDeleteAccount} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
};

export default VendorHistory;
