import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  useToast,
  Input,
  HStack,
} from "@chakra-ui/react";
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  writeBatch,
  doc,
  arrayUnion,
  updateDoc,
} from "firebase/firestore";
import { db } from "lib/firebase";
import { AuthContext } from "contexts/AuthContext";

// Styles personnalisés pour react-select
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#E2E8F0',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#CBD5E0',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

// MenuList virtualisé pour gérer un grand nombre d'options
const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={Math.min(maxHeight, options.length * height)}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const ManageAgencies = () => {
  const [name, setName] = useState('');
  const [manager, setManager] = useState([]);
  const [superManager, setSuperManager] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [availableVendors, setAvailableVendors] = useState([]);
  const [availableSuperManagers, setAvailableSuperManagers] = useState([]);
  const toast = useToast();
  const { user, userAgencies, refreshUserAgencies } = useContext(AuthContext);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user.profileData?.admin) {
        const vendorsSnapshot = await getDocs(collection(db, "vendors"));
        const vendorsData = vendorsSnapshot.docs.map(doc => ({ 
          value: doc.id, 
          label: doc.data().Name 
        }));
        vendorsData.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
        setAvailableVendors(vendorsData);

        const usersSnapshot = await getDocs(
          query(collection(db, "users"), where("manager", "==", true))
        );
        const managersData = usersSnapshot.docs.map(doc => ({
          value: doc.data().salesCode,
          label: doc.data().displayName || doc.data().email
        }));
        setAvailableSuperManagers(managersData);
      } else if (user.profileData?.superManager) {
        // Récupérer les agences gérées par le super manager
        const agenciesQuery = query(
          collection(db, "agencies"),
          where("superManager", "==", user.profileData.salesCode)
        );
        const agenciesSnapshot = await getDocs(agenciesQuery);
        const agencyIds = agenciesSnapshot.docs.map(doc => doc.id);

        // Récupérer les vendeurs de ces agences
        if (agencyIds.length > 0) {
          const vendorsQuery = query(
            collection(db, "vendors"),
            where("agencyId", "in", agencyIds)
          );
          const vendorsSnapshot = await getDocs(vendorsQuery);
          const vendorsData = vendorsSnapshot.docs.map(doc => ({
            value: doc.id,
            label: doc.data().Name
          }));
          vendorsData.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
          setAvailableVendors(vendorsData);
        }
      } else if (user.profileData?.manager) {
        try {
          const agenciesQuery = query(
            collection(db, "agencies"),
            where("managers", "array-contains", user.profileData.salesCode)
          );
          const agenciesSnapshot = await getDocs(agenciesQuery);
          
          if (!agenciesSnapshot.empty) {
            const agencyDoc = agenciesSnapshot.docs[0];
            const agencyVendors = agencyDoc.data().vendors || [];

            if (agencyVendors.length > 0) {
              const vendorsQuery = query(
                collection(db, "vendors"),
                where("__name__", "in", agencyVendors)
              );
              const vendorsSnapshot = await getDocs(vendorsQuery);
              const vendorsData = vendorsSnapshot.docs.map(doc => ({
                value: doc.id,
                label: doc.data().Name
              }));
              vendorsData.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
              setAvailableVendors(vendorsData);
            } else {
              setAvailableVendors([]);
            }
          } else {
            setAvailableVendors([]);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des vendeurs:", error);
          setAvailableVendors([]);
        }
      }
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    const loadInitialData = async () => {
      if (user?.profileData?.manager && isInitialLoad) {
        setIsInitialLoad(false);
        await refreshUserAgencies();
      }
    };
    
    loadInitialData();
  }, [user?.profileData?.manager, isInitialLoad]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (user.profileData?.admin) {
        const batch = writeBatch(db);

        // Création d'une agence avec un tableau de managers
        const agencyDocRef = await addDoc(collection(db, "agencies"), {
          name,
          managers: manager.map(m => m.value),
          superManager: superManager ? superManager.value : null,
          vendors: vendors.map(vendor => vendor.value),
          teams: []
        });

        // Mise à jour des vendeurs
        vendors.forEach(vendor => {
          const vendorRef = doc(db, "vendors", vendor.value);
          batch.update(vendorRef, { agencyId: agencyDocRef.id });
        });

        // Mise à jour du teamSalescode pour tous les managers
        for (const m of manager) {
          const managerUserQuery = query(
            collection(db, "users"),
            where("salesCode", "==", m.value)
          );
          const managerUserSnapshot = await getDocs(managerUserQuery);
          
          managerUserSnapshot.forEach((managerUserDoc) => {
            const userRef = doc(db, "users", managerUserDoc.id);
            const existingTeamSalescode = managerUserDoc.data().teamSalescode || [];
            const newVendors = vendors.map(vendor => vendor.value);
            const updatedTeamSalescode = Array.from(new Set([...existingTeamSalescode, ...newVendors]));

            batch.update(userRef, {
              teamSalescode: updatedTeamSalescode
            });
          });
        }

        // Mise à jour du super manager si sélectionné
        if (superManager) {
          const superManagerQuery = query(
            collection(db, "users"),
            where("salesCode", "==", superManager.value)
          );
          const superManagerSnapshot = await getDocs(superManagerQuery);
          
          superManagerSnapshot.forEach((userDoc) => {
            const userRef = doc(db, "users", userDoc.id);
            const existingTeamSalescode = userDoc.data().teamSalescode || [];
            const allSalesCodes = [
              ...manager.map(m => m.value),
              ...vendors.map(v => v.value)
            ];
            const updatedTeamSalescode = Array.from(new Set([...existingTeamSalescode, ...allSalesCodes]));
            
            batch.update(userRef, {
              superManager: true,
              managedAgencies: arrayUnion(agencyDocRef.id),
              teamSalescode: updatedTeamSalescode
            });
          });
        }

        await batch.commit();

        toast({
          title: "Succès",
          description: "Agence créée avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Réinitialisation des champs
        setName('');
        setManager([]);
        setSuperManager(null);
        setVendors([]);
      } else if (user.profileData?.manager) {
        console.log("Création d'équipe pour le manager:", user.profileData.salesCode);
        
        // Rafraîchir les agences si nécessaire
        if (!userAgencies || userAgencies.length === 0) {
          await refreshUserAgencies();
        }

        // Vérifier à nouveau après le rafraîchissement
        if (!userAgencies || userAgencies.length === 0) {
          throw new Error("Impossible de trouver l'agence associée à ce manager. Veuillez réessayer.");
        }

        // Utiliser directement l'agence du manager
        const agencyId = userAgencies[0].id;
        console.log("Agence trouvée:", { id: agencyId, data: userAgencies[0] });

        // Création d'une équipe
        const teamData = {
          name,
          manager: user.profileData.salesCode,
          vendors: vendors.map(vendor => vendor.value),
          agencyId: agencyId,
          createdAt: new Date().toISOString(),
        };

        console.log("Données de l'équipe à créer:", teamData);

        const batch = writeBatch(db);
        
        // Création de l'équipe
        const teamDocRef = doc(collection(db, "teams"));
        batch.set(teamDocRef, teamData);

        // Mise à jour de l'agence
        const agencyRef = doc(db, "agencies", agencyId);
        batch.update(agencyRef, {
          teams: arrayUnion(teamDocRef.id)
        });

        // Mise à jour du teamSalescode pour le manager
        const managerUserQuery = query(
          collection(db, "users"),
          where("salesCode", "==", user.profileData.salesCode)
        );
        const managerUserSnapshot = await getDocs(managerUserQuery);
        
        if (!managerUserSnapshot.empty) {
          managerUserSnapshot.forEach((managerUserDoc) => {
            const userRef = doc(db, "users", managerUserDoc.id);
            const existingTeamSalescode = managerUserDoc.data().teamSalescode || [];
            const newVendors = vendors.map(vendor => vendor.value);
            const updatedTeamSalescode = Array.from(new Set([...existingTeamSalescode, ...newVendors]));

            batch.update(userRef, {
              teamSalescode: updatedTeamSalescode
            });
          });
        }

        await batch.commit();
        console.log("Équipe créée avec succès");

        toast({
          title: "Succès",
          description: "Équipe créée avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Réinitialisation des champs
        setName('');
        setVendors([]);
      }
    } catch (error) {
      console.error("Erreur détaillée lors de la création:", error);
      toast({
        title: "Erreur",
        description: error.message || "Une erreur est survenue lors de la création",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSync = async () => {
    try {
      const batch = writeBatch(db);
      
      const usersSnapshot = await getDocs(
        query(collection(db, "users"), 
        where("teamSalescode", "!=", null))
      );

      // Créer un Map pour regrouper les managers par agence
      const agencyManagersMap = new Map();

      for (const userDoc of usersSnapshot.docs) {
        const userData = userDoc.data();
        const teamSalescode = userData.teamSalescode || [];

        if (!Array.isArray(teamSalescode) || teamSalescode.length === 0) continue;
        if (!userData.salesCode) continue;

        // Pour les managers
        if (userData.manager) {
          if (!userData.salesCode) continue;

          // Vérifier si le manager est déjà associé à une agence
          const existingAgencyQuery = query(
            collection(db, "agencies"),
            where("managers", "array-contains", userData.salesCode)
          );
          const existingAgencySnapshot = await getDocs(existingAgencyQuery);

          if (existingAgencySnapshot.empty) {
            // Si pas d'agence existante, créer une nouvelle
            const agencyData = {
              name: `Agence de ${userData.displayName || userData.email}`,
              managers: [userData.salesCode],
              vendors: teamSalescode,
              teams: []
            };

            const agencyRef = doc(collection(db, "agencies"));
            batch.set(agencyRef, agencyData);
          } else {
            // Si agence existante, mettre à jour les vendeurs
            const agencyDoc = existingAgencySnapshot.docs[0];
            const currentVendors = agencyDoc.data().vendors || [];
            const updatedVendors = Array.from(new Set([...currentVendors, ...teamSalescode]));
            
            batch.update(doc(db, "agencies", agencyDoc.id), {
              vendors: updatedVendors
            });
          }
        }
        // Pour les leaders
        else if (userData.leader) {
          if (!userData.managerSalesCode || !userData.salesCode) continue;

          const managerQuery = query(
            collection(db, "agencies"),
            where("managers", "array-contains", userData.managerSalesCode)
          );
          const managerSnapshot = await getDocs(managerQuery);

          if (!managerSnapshot.empty) {
            const agencyDoc = managerSnapshot.docs[0];

            // Vérifier si le leader a déjà une équipe
            const existingTeamQuery = query(
              collection(db, "teams"),
              where("manager", "==", userData.salesCode),
              where("agencyId", "==", agencyDoc.id)
            );
            const existingTeamSnapshot = await getDocs(existingTeamQuery);

            if (existingTeamSnapshot.empty) {
              const teamData = {
                name: `Équipe de ${userData.displayName || userData.email}`,
                manager: userData.salesCode,
                vendors: teamSalescode,
                agencyId: agencyDoc.id
              };

              const teamRef = doc(collection(db, "teams"));
              batch.set(teamRef, teamData);
              
              batch.update(doc(db, "agencies", agencyDoc.id), {
                teams: arrayUnion(teamRef.id)
              });
            } else {
              const teamDoc = existingTeamSnapshot.docs[0];
              const currentVendors = teamDoc.data().vendors || [];
              const updatedVendors = Array.from(new Set([...currentVendors, ...teamSalescode]));
              
              batch.update(doc(db, "teams", teamDoc.id), {
                vendors: updatedVendors
              });
            }
          }
        }
      }

      await batch.commit();

      toast({
        title: "Succès",
        description: "Synchronisation effectuée avec succès",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

    } catch (error) {
      console.error("Erreur lors de la synchronisation:", error);
      toast({
        title: "Erreur",
        description: error.message || "Une erreur est survenue lors de la synchronisation",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!user.profileData?.admin && !user.profileData?.manager) {
    return null;
  }

  return (
    <Box>
      {user.profileData?.admin && (
        <HStack mb={4} spacing={4}>
          <Button colorScheme="purple" onClick={handleSync}>
            Synchroniser les utilisateurs
          </Button>
        </HStack>
      )}
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>{user.profileData?.admin ? "Nom de l'agence" : "Nom de l'équipe"}</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>

          {user.profileData?.admin && (
            <>
              <FormControl isRequired>
                <FormLabel>Managers</FormLabel>
                <Select
                  styles={customStyles}
                  components={{ MenuList }}
                  options={availableVendors}
                  value={manager}
                  onChange={setManager}
                  isMulti
                  placeholder="Sélectionnez les managers..."
                  closeMenuOnSelect={false}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Super Manager</FormLabel>
                <Select
                  styles={customStyles}
                  components={{ MenuList }}
                  options={availableSuperManagers}
                  value={superManager}
                  onChange={setSuperManager}
                  placeholder="Sélectionnez un super manager..."
                  isClearable
                />
              </FormControl>
            </>
          )}

          <FormControl isRequired>
            <FormLabel>Vendeurs</FormLabel>
            <Select
              styles={customStyles}
              components={{ MenuList }}
              options={availableVendors}
              value={vendors}
              onChange={setVendors}
              isMulti
              placeholder="Sélectionnez des vendeurs..."
              closeMenuOnSelect={false}
            />
          </FormControl>

          <Button type="submit" colorScheme="blue">
            {user.profileData?.admin ? "Créer l'agence" : "Créer l'équipe"}
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default ManageAgencies;
